<template>
  <div>
    <!--begin::Dashboard-->
    <v-col class="row">
      <v-col cols="12" sm="12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Home/Home.svg"/>
            </span>
          </div>
          <div class="alert-text mt-2">
            <h1><b>Welcome to Admin Panel</b></h1>
          </div>
        </b-alert>
      </v-col>
<!--      <v-col cols="12" sm="12">-->
<!--        <DashboardStats />-->
<!--      </v-col>-->
<!--      <v-col cols="12" sm="12">-->
<!--        <DashboardReqs />-->
<!--      </v-col>-->
    </v-col>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
// import DashboardStats from "@/view/pages/realstate/dashboard/inc/DashboardStats.vue";
// import DashboardReqs from "@/view/pages/realstate/dashboard/inc/DashboardReqs.vue";

export default {
  name: "dashboard",
  components: {
    // DashboardStats,
    // DashboardReqs
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
